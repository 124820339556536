<template>
    <v-container fluid no-gutters class="mx-0 px-0">
        <v-row justify="center" class="my-2">
            <v-col cols="6" md="5">
                <v-row justify="center" align="center">
                    <v-col cols="12" md="6" align="center">
                        <v-card :height="$vuetify.breakpoint.mobile ? '126px' : '126px'" class="rounded-lg clickable" @click.stop="goToTeam(match.team1)">
                            <v-row align="center" class="mx-1">
                                <v-col cols="12" align="center" class="py-2">
                                    <v-img
                                        :height="$vuetify.breakpoint.mobile? '70px' : '80px'"
                                        contain
                                        :src="`${assetsUrl}/${match.team1?.logo}`"
                                    />
                                </v-col>
                                <v-col cols="12" align="center" class="my-0 py-0">
                                    <div class="secondary-font font-weight-bold" :class="$vuetify.breakpoint.mobile? 'caption' : 'subtitle-2'">{{ match.team1?.name }}</div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
            <v-col cols="6" md="5">
                <v-row justify="center">
                    <v-col cols="12" md="6">
                        <v-card :height="$vuetify.breakpoint.mobile ? '126px' : '126px'" class="rounded-lg clickable" @click.stop="goToTeam(match.team2)">
                            <v-row align="center" class="mx-1">
                                <v-col cols="12" align="center" class="py-2">
                                    <v-img
                                        v-if="match.team2.logo"
                                        :height="$vuetify.breakpoint.mobile? '70px' : '80px'"
                                        contain
                                        :src="`${assetsUrl}/${match.team2?.logo}`"
                                    />
                                    <v-icon v-else :style="$vuetify.breakpoint.mobile ? 'font-size: 70px' : 'font-size: 80px'" class="orange--text"> mdi-basketball </v-icon>
                                </v-col>
                                <v-col cols="12" align="center" class="my-0 py-0">
                                    <div class="secondary-font font-weight-bold" :class="$vuetify.breakpoint.mobile? 'caption' : 'subtitle-2'">{{ match.team2?.name }}</div>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-row justify="center">
            <v-col cols="12" md="10">
                <v-card class="rounded-lg px-1">
                    <v-row v-for="(stat, index) in stats" :key="index" justify="center" align="center" class="bottom-border">
                        <v-col cols="5" align="start">
                            <v-row align="center">
                                <v-col cols="4" align="center">
                                    <span class="font-weight-bold secondary-font" :class="!$vuetify.breakpoint.mobile? 'subtitle-1' : 'subtitle-2'">
                                        {{ stat.team1[stat.value] }}
                                    </span>
                                </v-col>
                                <v-col cols="8" align="center">
                                    <v-progress-linear
                                        background-color="grey lighten-3"
                                        :color="match.team1?.color ? match.team1?.color : 'orange'"
                                        class="elevation-2"
                                        rounded
                                        height="20"
                                        reverse
                                        :value="stat.value.includes('percentage') ? stat.team1[stat.value] : stat.team1[stat.value] / (stat.team2[stat.value] + stat.team1[stat.value]) * 100"
                                    >
                                        <template v-slot:default="{ }">
                                            <v-card class="rounded-lg px-2">
                                                <strong>{{ getStat(stat.value, stat.team1) }}</strong>
                                            </v-card>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="2" align="center" class="px-0 mx-0">
                            <v-row align="center" justify="center" class="px-0 mx-0">
                                <v-col cols="12" class="px-0 mx-0">
                                    <span class="font-weight-bold custom-orange-text" style="font-family: Mansalva, Arial, sans-serif !important;" :class="!$vuetify.breakpoint.mobile? 'subtitle-1' : 'subtitle-2'">{{ stat.text }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                        <v-col cols="5" align="end">
                            <v-row align="center">
                                <v-col cols="8" align="center">
                                    <v-progress-linear
                                        background-color="grey lighten-3"
                                        :color="match.team2?.color ? match.team2?.color : 'orange'"
                                        class="elevation-2"
                                        rounded
                                        height="20"
                                        :value="stat.value.includes('percentage') ? stat.team2[stat.value] : stat.team2[stat.value] / (stat.team2[stat.value] + stat.team1[stat.value]) * 100"
                                    >
                                        <template v-slot:default="{ }">
                                            <v-card class="rounded-lg px-2">
                                                <strong>{{ getStat(stat.value, stat.team2) }}</strong>
                                            </v-card>
                                        </template>
                                    </v-progress-linear>
                                </v-col>
                                <v-col cols="4" align="center">
                                    <span class="font-weight-bold secondary-font" :class="!$vuetify.breakpoint.mobile? 'subtitle-1' : 'subtitle-2'">{{ stat.team2[stat.value] }}</span>
                                </v-col>
                            </v-row>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    export default {
        props: {
            match: {
                type: Object,
                default: null
            },
            mvp: {
                type: Boolean,
                default: true
            }
        },
        data() {
            return {
                assetsUrl: 'https://storage.googleapis.com',
                headers: [
                    {
                        text: 'Club',
                        align: 'start',
                        value: 'club',
                        width: '220px',
                        sortable: false
                    },
                    { text: 1, value: 'period1', sortable: false, width: '10px' },
                    { text: 2, value: 'period2', sortable: false, width: '10px' },
                    { text: 3, value: 'period3', sortable: false, width: '10px' },
                    { text: 4, value: 'period4', sortable: false, width: '10px' }
                ],
                periods: [
                    {
                        club: 'Olympiacos',
                        period1: {
                            value: 20,
                            color: false
                        },
                        period2: {
                            value: 25,
                            color: true
                        },
                        period3: {
                            value: 20,
                            color: false
                        },
                        period4: {
                            value: 23,
                            color: true
                        },
                        image: 'https://upload.wikimedia.org/wikipedia/en/thumb/7/7f/Olympiacos_BC_logo.svg/1200px-Olympiacos_BC_logo.svg.png'
                    },
                    {
                        club: 'Los Angeles Lakers',
                        period1: {
                            value: 23,
                            color: true
                        },
                        period2: {
                            value: 18,
                            color: false
                        },
                        period3: {
                            value: 25,
                            color: true
                        },
                        period4: {
                            value: 13,
                            color: false
                        },
                        image: 'https://upload.wikimedia.org/wikipedia/commons/thumb/3/3c/Los_Angeles_Lakers_logo.svg/1200px-Los_Angeles_Lakers_logo.svg.png'
                    }
                ],
                stats: [
                    { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                    { text: '2P%', value: 'two_points_percentage', team1: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 }, team2: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 } },
                    { text: '3P%', value: 'three_points_percentage', team1: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 }, team2: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 } },
                    { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 }, team2: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 } },
                    { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                    { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                    { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                    { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                    { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                    { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                    { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                    { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } }
                ]
            }
        },
        watch: {
            match: {
                handler() {
                    this.stats = [
                        { text: 'Pts', value: 'points', team1: { player: null, points: 0 }, team2: { player: null, points: 0 } },
                        { text: '2P%', value: 'two_points_percentage', team1: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 }, team2: { player: null, twoPointAttempts: 0, twoPointsMade: 0, two_points_percentage: 0 } },
                        { text: '3P%', value: 'three_points_percentage', team1: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 }, team2: { player: null, threePointAttempts: 0, threePointsMade: 0, three_points_percentage: 0 } },
                        { text: 'FT%', value: 'free_throw_percentage', team1: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 }, team2: { player: null, freeThrowAttempts: 0, freeThrowsMade: 0, free_throw_percentage: 0 } },
                        { text: 'OR', value: 'offensiveRebounds', team1: { player: null, offensiveRebounds: 0 }, team2: { player: null, offensiveRebounds: 0 } },
                        { text: 'DR', value: 'defensiveRebounds', team1: { player: null, defensiveRebounds: 0 }, team2: { player: null, defensiveRebounds: 0 } },
                        { text: 'TR', value: 'rebounds', team1: { player: null, rebounds: 0 }, team2: { player: null, rebounds: 0 } },
                        { text: 'AST', value: 'assists', team1: { player: null, assists: 0 }, team2: { player: null, assists: 0 } },
                        { text: 'STL', value: 'steals', team1: { player: null, steals: 0 }, team2: { player: null, steals: 0 } },
                        { text: 'TO', value: 'turnovers', team1: { player: null, turnovers: 0 }, team2: { player: null, turnovers: 0 } },
                        { text: 'BLK', value: 'blockedShots', team1: { player: null, blockedShots: 0 }, team2: { player: null, blockedShots: 0 } },
                        { text: 'FC', value: 'personalFouls', team1: { player: null, personalFouls: 0 }, team2: { player: null, personalFouls: 0 } }
                    ]
                    this.getMatchValues()
                }
            }
        },
        created() {
            this.getMatchValues()
        },
        methods: {
            getStat(value, stat) {
                if (value === 'two_points_percentage') {
                    return `${stat.twoPointsMade}/${stat.twoPointAttempts}`
                } else if (value === 'three_points_percentage') {
                    return `${stat.threePointsMade}/${stat.threePointAttempts}`
                } else if (value === 'free_throw_percentage') {
                    return `${stat.freeThrowsMade}/${stat.freeThrowAttempts}`
                } else {
                    return ''
                }
            },
            getMatchValues() {
                this.stats.forEach((stat) => {
                    this.match.team1.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            if (stat.value === 'two_points_percentage') {
                                stat.team1.twoPointAttempts += player.stats[0].twoPointAttempts
                                stat.team1.twoPointsMade += player.stats[0].twoPointsMade
                            } else if (stat.value === 'three_points_percentage') {
                                stat.team1.threePointAttempts += player.stats[0].threePointAttempts
                                stat.team1.threePointsMade += player.stats[0].threePointsMade
                            } else if (stat.value === 'free_throw_percentage') {
                                stat.team1.freeThrowAttempts += player.stats[0].freeThrowAttempts
                                stat.team1.freeThrowsMade += player.stats[0].freeThrowsMade
                            } else {
                                stat.team1[stat.value] += player.stats[0][stat.value]
                            }
                        }
                    })
                    stat.team1.two_points_percentage = (stat.team1.twoPointsMade / (stat.team1.twoPointAttempts ? stat.team1.twoPointAttempts : 1) * 100).toFixed(0)
                    stat.team1.three_points_percentage = (stat.team1.threePointsMade / (stat.team1.threePointAttempts ? stat.team1.threePointAttempts : 1) * 100).toFixed(0)
                    stat.team1.free_throw_percentage = (stat.team1.freeThrowsMade / (stat.team1.freeThrowAttempts ? stat.team1.freeThrowAttempts : 1) * 100).toFixed(0)
                    this.match.team2.players.forEach((player) => {
                        if (player.stats.length > 0) {
                            if (stat.value === 'two_points_percentage') {
                                stat.team2.twoPointAttempts += player.stats[0].twoPointAttempts
                                stat.team2.twoPointsMade += player.stats[0].twoPointsMade
                            } else if (stat.value === 'three_points_percentage') {
                                stat.team2.threePointAttempts += player.stats[0].threePointAttempts
                                stat.team2.threePointsMade += player.stats[0].threePointsMade
                            } else if (stat.value === 'free_throw_percentage') {
                                stat.team2.freeThrowAttempts += player.stats[0].freeThrowAttempts
                                stat.team2.freeThrowsMade += player.stats[0].freeThrowsMade
                            } else {
                                stat.team2[stat.value] += player.stats[0][stat.value]
                            }
                        }
                    })
                    stat.team2.two_points_percentage = (stat.team2.twoPointsMade / (stat.team2.twoPointAttempts ? stat.team2.twoPointAttempts : 1) * 100).toFixed(0)
                    stat.team2.three_points_percentage = (stat.team2.threePointsMade / (stat.team2.threePointAttempts ? stat.team2.threePointAttempts : 1) * 100).toFixed(0)
                    stat.team2.free_throw_percentage = (stat.team2.freeThrowsMade / (stat.team2.freeThrowAttempts ? stat.team2.freeThrowAttempts : 1) * 100).toFixed(0)
                })
            },
            goToTeam(team) {
                this.$router.push({ name: 'Team', params: { id: team.id } })
            }
        }
    }
</script>

<style>
    .v-tabs:not(.v-tabs--vertical).v-tabs--right > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__next,
    .v-tabs:not(.v-tabs--vertical):not(.v-tabs--right) > .v-slide-group--is-overflowing.v-tabs-bar--is-mobile:not(.v-slide-group--has-affixes) .v-slide-group__prev {
        display: none;
    }

    .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tbody > tr > th,
    .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > tfoot > tr > th,
    .v-application--is-ltr .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
        text-align: center !important;
    }
</style>

<style scoped>

    .bottom-border {
        border-bottom: 1px solid rgb(231, 225, 225);
    }

    .winning-period {
        background: linear-gradient(#00f7d1, #00ec80);
    }
</style>
